var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","items-per-page":50},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[(items.length === 0)?_c('tr',[_c('td',{staticClass:"text-center p-2",attrs:{"colspan":_vm.headers.length}},[_vm._v("No results")])]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.created_at)))]),_c('td',[_vm._v(_vm._s(item.ip))]),_c('td',[(item.user_agent.simple_parse.device)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_vm._v(" "+_vm._s(item.user_agent.simple_parse.device)+" ")]):_vm._e(),_c('br'),(item.user_agent.simple_parse.operating_system)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_vm._v(" "+_vm._s(item.user_agent.simple_parse.operating_system)+" ")]):_vm._e()],1),_c('td',[(item.user_agent.browser)?[_vm._v(" "+_vm._s(item.user_agent.browser.name)+" "+_vm._s(item.user_agent.browser.version)+" ")]:_vm._e()],2),_c('td',[_c('v-chip',{attrs:{"color":item.user_agent.is_robot ? 'error' : 'success',"text-color":"white","x-small":"","label":""}},[_vm._v(" "+_vm._s(item.user_agent.is_robot ? "ROBOT" : "NORMAL")+" ")])],1),_c('td',[(item.influencer_channel.influencer.user)?_c('router-link',{attrs:{"title":item.influencer_channel.influencer.user.full_name,"to":{
              name: 'influencer',
              params: { id: item.influencer_channel.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.influencer_channel.influencer.user.full_name)+" ")]):_vm._e()],1),_c('td',[(item.campaign)?_c('router-link',{attrs:{"title":item.campaign.name,"to":{
                name: 'campaign',
                params: { id: item.campaign.uuid }
             }}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.campaign.name,35))+" ")]):_vm._e()],1),_c('td',[(item.campaign.business)?_c('router-link',{attrs:{"title":item.campaign.business.display_name,"to":{
                name: 'business',
                params: { id: item.campaign.business.uuid }
              }}},[_vm._v(" "+_vm._s(item.campaign.business.display_name)+" ")]):_vm._e()],1)])})],2)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),(_vm.pagination)?_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }