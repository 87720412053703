<template>
    <div>
        <v-card>
            <v-card-title>
                <div>
                    <div class="headline">Clicks report</div>
                    <span class="subtitle-1 light-grey"></span>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            outlined
                            color="primary"
                            @click="
                                filtersTmp = JSON.parse(JSON.stringify(filters));
                                filterDialog = true;
                            "
                        >
                            <v-icon small>fal fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filters</span>
                </v-tooltip>
            </v-card-title>
            <div class="date-range-template">
                <date-range-picker
                    :value="{
                        start_date: filters.start_date,
                        end_date: filters.end_date
                    }"
                    @changePeriod="changePeriod"
                    :ranges="ranges"
                ></date-range-picker>
            </div>
            <v-card-text>
                <div class="text-right total-shown" v-if="clicks.length">
                    {{ clicks.length }} out of {{ totalAmount | local_numbers }}
                </div>
                <clicks-data-table
                    :items="clicks"
                    :loading="loading"
                    pagination
                    :has-less="page > 1"
                    :has-more="hasMore"
                    :total="total"
                    :page="page"
                    @changePage="_getClicks($event)"
                ></clicks-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="filterDialog" max-width="550px">
            <v-form @submit.prevent="filter()">
                <v-card>
                    <v-card-title class="headline" primary-title>
                        Filter Report
                    </v-card-title>
                    <v-card-text>
                        <campaign-autocomplete
                            v-model="filtersTmp.campaign_id"
                            :return-object="false"
                        ></campaign-autocomplete>
                        <business-autocomplete
                            v-model="filtersTmp.business_id"
                            :return-object="false"
                        ></business-autocomplete>
                        <influencer-autocomplete
                            v-model="filtersTmp.influencer_id"
                            :return-object="false"
                        ></influencer-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click="filter()">
                            Filter
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import search_filters_url from "@/helpers/mixins/search_filters_url";
    import local_numbers from "@/helpers/filters/local_numbers";
    import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
    import InfluencerAutocomplete from "../../autocompletes/InfluencerAutocomplete";
    import CampaignAutocomplete from "../../autocompletes/CampaignAutocomplete";
    import ClicksDataTable from '../data-tables/ClicksDataTable.vue';
    import DateRangePicker from "@/components/common/filters/DateRangePicker";

    export default {
        components: {
            DateRangePicker,
            ClicksDataTable,
            BusinessAutocomplete,
            InfluencerAutocomplete,
            CampaignAutocomplete,
        },
      filters: { local_numbers },
        mixins: [search_filters_url],
        data() {
            const today = new Date();
            const yesterday = this.$moment(today).subtract(1, "days");
            const last7days = this.$moment(today).subtract(6, "days");
            const last30days = this.$moment(today).subtract(29, "days");
            const lastWeekStart = this.$moment(today)
                .subtract(1, "week")
                .isoWeekday(1)
                .startOf("isoweek");
            const lastWeekEnd = this.$moment(today)
                .subtract(1, "week")
                .isoWeekday(1)
                .endOf("isoweek");
            const lastMonthStart = this.$moment(today)
                .subtract(1, "month")
                .startOf("month");
            const lastMonthEnd = this.$moment(today)
                .subtract(1, "month")
                .endOf("month");

            return {
                loading: false,
                totalAmount: null,
                clicks: [],
                filterDialog: false,
                filtersTmp: {},
                filters: {},
                page: 1,
                total: null,
                limit: 50,
                hasMore: true,
                ranges: {
                    USERDEFINED: [null, null],
                    "TODAY": [today, today],
                    "YESTERDAY": [yesterday._d, yesterday._d],
                    "LAST WEEK": [lastWeekStart._d, lastWeekEnd._d],
                    "LAST MONTH": [lastMonthStart._d, lastMonthEnd._d],
                    "LAST 7 DAYS": [last7days._d, today],
                    "LAST 30 DAYS": [last30days._d, today]
                },
            }
        },
        computed: {
            filtersCount() {
                return Object.keys(this.filters).length;
            }
        },
        watch: {
            filters: {
                handler: function () {
                    // reset
                    this.clicks = [];
                    this.page = 1;
                    this.loading = false;
                    this.hasMore = true;
                    this.setUrlSearchFilters(this.filters);
                    this._getClicks();
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions('core', ['getClicks']),
            changePeriod(event) {
                this.filters.start_date = event.start_date;
                this.filters.end_date = event.end_date;
                this.setUrlSearchFilters(this.filters);
                this._getClicks(1);
            },
            _getClicks(page = 1) {
                if (this.loading) {
                    return;
                }
                
                this.page = page;
                this.clicks = [];
                this.loading = true;

                const filters = this.cloneDeep(this.filters);

                const params = {
                    ...filters,
                    limit: this.limit,
                    page: this.page,
                };

                this.getClicks(params).then(response => {
                    this.clicks = response.data;

                    // If no more items
                    if (response.data.length !== params.limit) {
                        this.hasMore = false;
                    }

                    this.loading = false;
                    this.total = response.lastPage;
                    this.totalAmount = response.total;
                });
            },
            filter() {
                this.filters = this.cloneDeep(this.filtersTmp);
                this.filterDialog = false;
            },
        },
        created() {
            this.filters = this.getUrlSearchFilters();
        }
    }
</script>