<template>
  <div>
    <v-data-table
     :headers="headers"
     :items="items"
     :loading="loading"
     hide-default-footer
     :items-per-page="50"
    >
      <v-progress-linear
       slot="progress"
       color="primary"
       indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-if="items.length === 0">
          <td class="text-center p-2" :colspan="headers.length">No results</td>
        </tr>
        <tr v-for="item in items" :key="item.uuid">
          <td>{{ item.created_at | utc_to_local_datetime }}</td>
          <td>{{ item.ip }}</td>
          <td>
            <v-chip
               v-if="item.user_agent.simple_parse.device"
               color="secondary"
               label
               text-color="primary"
               x-small
            >
              {{ item.user_agent.simple_parse.device }}
            </v-chip>
            <br/>
            <v-chip
               v-if="item.user_agent.simple_parse.operating_system"
               color="secondary"
               label
               text-color="primary"
               x-small
            >
              {{ item.user_agent.simple_parse.operating_system }}
            </v-chip>
          </td>
          <td>
            <template v-if="item.user_agent.browser">
              {{ item.user_agent.browser.name }} {{ item.user_agent.browser.version }}
            </template>
          </td>
          <td>
            <v-chip
               :color="item.user_agent.is_robot ? 'error' : 'success'"
               text-color="white"
               x-small
               label
            >
              {{ item.user_agent.is_robot ? "ROBOT" : "NORMAL" }}
            </v-chip>
          </td>
          <td>
            <router-link
               v-if="item.influencer_channel.influencer.user"
               :title="item.influencer_channel.influencer.user.full_name"
               :to="{
                name: 'influencer',
                params: { id: item.influencer_channel.influencer.uuid }
                }"
            >
              {{ item.influencer_channel.influencer.user.full_name }}
            </router-link>
          </td>
          <td>
            <router-link
               v-if="item.campaign"
               :title="item.campaign.name"
               :to="{
                  name: 'campaign',
                  params: { id: item.campaign.uuid }
               }"
              >
                {{ item.campaign.name | truncate(35) }}
            </router-link>
          </td>
          <td>
              <router-link
                v-if="item.campaign.business"
                :title="item.campaign.business.display_name"
                :to="{
                  name: 'business',
                  params: { id: item.campaign.business.uuid }
                }"
            >
              {{ item.campaign.business.display_name }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
       v-if="pagination"
       v-model="currPage"
       class="my-2"
       :length="total"
       :total-visible="7"
       @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<style scoped>
td {
  white-space: nowrap;
}
</style>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import truncate from "@/helpers/filters/truncate";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime,
    truncate
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false
    },
    hasLess: {
      type: Boolean,
      required: false,
      default: false
    },
    hasMore: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      headers: [],
      currPage: 1
    };
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    },
  },
  mounted() {
    this.headers = [
      {text: "Created at", value: "created_at", sortable: false},
      {text: "IP address", value: "ip", sortable: false},
      {text: "Device", value: "user_agent.device_size", sortable: false},
      {text: "Browser", value: "user_agent.browser.name", sortable: false},
      {text: "User type", value: "user_agent.is_robot", sortable: false},
      {text: "Influencer", value: "influencer_channel.influencer.user.full_name", sortable: false},
      {text: "Campaign", value: "campaign.name", sortable: false},
      {text: "Business", value: "campaign.business.display_name", sortable: false},
    ];
  },
}
</script>
